<template>
  <div class="bg-white fixed top-0 right-0 bottom-0 z-10 max-w-25 modal-shadow">
    <ModalWrapper :title="$t('settings.display.title')" :title-classes="['text-black', 'font-bold']" title-size="24" no-footer @onClose="$emit('onClose')">
      <div class="pa-5 border-b border-lightGrey-200">
        <span class="text-xs font-bold text-grey-100 uppercase">
          {{ $t('settings.display.map') }}
        </span>
        <div class="flex justify-between items-center py-3">
          <span class="font-semibold text-black">
            {{ $t('settings.display.hide_events') }}
          </span>
          <ControlsSwitch :value="toggleEvents" @onChange="hideEvents" />
        </div>
      </div>
      <div class="pa-5 border-b border-lightGrey-200 mt-6">
        <span class="text-xs font-bold text-grey-100 uppercase">
          {{ $t('settings.display.monitoring') }}
        </span>
        <!-- start -->
        <div class="pa-5 border-t border-lightGrey-200">
          <div class="flex justify-between items-center gap-10 py-3">
            <span class="font-semibold text-black mr-3">
              {{ $t('settings.display.object_grouping') }}
            </span>
            <ControlsSwitch :value="isGroupingObjects" @onChange="changeGroupingObjects" />
          </div>
        </div>
        <!-- end -->
        <div v-if="isGroupingObjects" class="flex justify-between items-center gap-3 py-3">
          <span class="font-semibold text-black mr-4">
            {{ $t('settings.display.group_by_dots') }}
          </span>
          <GroupDots />
        </div>
        <div class="pa-5 border-t border-lightGrey-200">
          <div class="flex justify-between items-center gap-10 py-3">
            <span class="font-semibold text-black mr-3">
              {{ $t('settings.display.show_annotation') }}
            </span>
            <ControlsSwitch :value="objectAnnotationSwitch" @onChange="changeAnnotationSwitch" />
          </div>
        </div>
      </div>

      <div class="pa-5 border-b border-lightGrey-200 mt-6">
        <span class="text-xs font-bold text-grey-100 uppercase">
          {{ $t('settings.display.notification') }}
        </span>
        <div class="flex justify-between items-center py-3">
          <span class="font-semibold text-black">
            {{ $t('settings.display.hide_notification') }}
          </span>
          <ControlsSwitch :value="toggleNotification" @onChange="hideNotification" />
        </div>
      </div>

      <div class="pa-5 border-b border-lightGrey-200 mt-6">
        <span class="text-xs font-bold text-grey-100 uppercase">
          {{ $t('settings.display.geozones') }}
        </span>
        <div class="flex justify-between items-center gap-3 py-3">
          <span class="font-semibold text-black mr-4">
            {{ $t('settings.display.show_geozones') }}
          </span>
          <ControlsSwitch :value="toggleGeozones" @onChange="showGeozones" />
        </div>
        <div class="flex justify-between items-center gap-3 py-3">
          <span class="font-semibold text-black mr-4"> Группировать на карте </span>
          <ControlsSwitch :value="toggleGeozonesCluster" @onChange="clusterGeozones" />
        </div>
        <div class="pa-5 border-t border-lightGrey-200">
          <div class="flex justify-between items-center gap-3 py-3">
            <span class="font-semibold text-black mr-4">
              {{ $t('settings.display.area_measurement') }}
            </span>
            <MeasurementUnits @onChangeMeasurement="changeMeasurement" />
          </div>
        </div>
      </div>
      <div class="pa-5 border-b border-lightGrey-200 mt-6">
        <span class="text-xs font-bold text-grey-100 uppercase">
          {{ $t('hide.tracks') }}
        </span>
        <div class="flex justify-between items-center py-3">
          <span class="font-semibold text-black">
            {{ $t('settings.display.track_weight') }}
          </span>
          <div style="width: 110px">
            <el-select class="weight-select" size="small" v-model="trackWeight" @change="changeTrackWeight">
              <el-option v-for="(item, index) in trackWeights" :key="index" :label="item.label" :value="item.value" />
            </el-select>
          </div>
        </div>
      </div>
      <!-- start tracks colors settings -->
      <div class="pa-5 border-b border-lightGrey-200 mt-6">
        <TrackColors v-if="hasRole" />
      </div>
    </ModalWrapper>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue'

import ModalWrapper from '@/components/companyControlsCenter/components/ControlsModalWrapper.vue'
import ControlsSwitch from '@/components/companyControlsCenter/components/ControlsSwitch.vue'
import GroupDots from '@/components/companyControlsCenter/components/DisplaySettings/GroupDots.vue'
import MeasurementUnits from '@/components/companyControlsCenter/components/DisplaySettings/MeasurementUnits.vue'
import TrackColors from './components/trackColors/TrackColors.vue'
import { allRoles } from '@/constants'

import { createVuexHelpers } from 'vue2-helpers'

const { useGetters, useMutations, useActions } = createVuexHelpers()

const { me } = useGetters('login', ['me'])
const { objectAnnotationSwitch } = useGetters('units', ['objectAnnotationSwitch'])

const { SET_OBJECT_ANNOTATION_SWITCH } = useMutations('units', ['SET_OBJECT_ANNOTATION_SWITCH'])

const { isHidingGeo } = useGetters('map', ['isHidingGeo'])
const { trackWeight } = useGetters('map', ['trackWeight'])

const { TOGGLE_EVENTS, TOGGLE_NOTIFICATION, TOGGLE_GEOZONES, SETTRACK_WEIGHT, SET_GROUPING_OBJECTS, SET_RADIUS_VALUE } = useMutations('map', ['TOGGLE_EVENTS', 'TOGGLE_NOTIFICATION', 'TOGGLE_GEOZONES', 'SETTRACK_WEIGHT', 'SET_GROUPING_OBJECTS', 'SET_RADIUS_VALUE'])

const { SET_GEOZONES_DIMENSIONS, SET_CLUSTER_GEOZONES } = useActions('geozones', ['SET_GEOZONES_DIMENSIONS', 'SET_CLUSTER_GEOZONES'])

const toggleEvents = ref(false)
const toggleNotification = ref(false)
const toggleGeozones = ref(false)
const toggleGeozonesCluster = ref(true)
const isGroupingObjects = ref(false)
const trackWeights = [
  { value: 2, label: '2px' },
  { value: 3, label: '3px' },
  { value: 4, label: '4px' },
  { value: 5, label: '5px' },
  { value: 6, label: '6px' },
  { value: 8, label: '8px' },
  { value: 10, label: '10px' },
  { value: 14, label: '14px' }
]

const hasRole = computed(() => {
  const userRole = me.value.role.key
  return [allRoles.ADMIN, allRoles.SUPERVISOR, allRoles.TARIFF_EDITOR, allRoles.CONTROLLER].includes(userRole)
})
const changeGroupingObjects = value => {
  isGroupingObjects.value = value
  localStorage.setItem('groupingObjects', JSON.stringify(value))
  SET_GROUPING_OBJECTS(value)
  if (!value) {
    SET_RADIUS_VALUE(0)
    localStorage.setItem('clusterRadiusCount', 0)
  }
}
const changeTrackWeight = value => {
  localStorage.setItem('trackWeight', JSON.stringify(value))
  SETTRACK_WEIGHT(value)
}
const hideEvents = value => {
  toggleEvents.value = value
  localStorage.setItem('hideevents', JSON.stringify(toggleEvents.value))

  TOGGLE_EVENTS(toggleEvents.value)
}

const hideNotification = value => {
  toggleNotification.value = value
  localStorage.setItem('hideNotification', JSON.stringify(toggleNotification.value))

  TOGGLE_NOTIFICATION(toggleNotification.value)
}

const showGeozones = value => {
  toggleGeozones.value = value

  localStorage.setItem('hidegeozones', JSON.stringify(!toggleGeozones.value))
  TOGGLE_GEOZONES(!toggleGeozones.value)
}
const clusterGeozones = value => {
  toggleGeozonesCluster.value = value
  localStorage.setItem('clusterGeozones', JSON.stringify(toggleGeozonesCluster.value))
  SET_CLUSTER_GEOZONES(toggleGeozonesCluster.value)
}
const changeAnnotationSwitch = value => {
  SET_OBJECT_ANNOTATION_SWITCH(value)
}

const changeMeasurement = async value => {
  try {
    const properties = {
      geozone_dimensions: value
    }
    const myself = { ...me.value, properties }

    await SET_GEOZONES_DIMENSIONS(myself)
  } catch (err) {
    throw new Error(err)
  }
}

onMounted(() => {
  const isToggleEventsExist = JSON.parse(localStorage.getItem('hideevents'))
  const isToggleNotification = JSON.parse(localStorage.getItem('hideNotification'))

  toggleEvents.value = isToggleEventsExist === null ? false : isToggleEventsExist

  toggleNotification.value = isToggleNotification === null ? false : isToggleNotification

  toggleGeozones.value = !isHidingGeo.value
  const trackWeightStorage = JSON.parse(localStorage.getItem('trackWeight'))
  if (trackWeightStorage === null) {
    SETTRACK_WEIGHT(5)
  } else {
    SETTRACK_WEIGHT(trackWeightStorage)
  }
  const isGroupingObjectsStorage = JSON.parse(localStorage.getItem('groupingObjects'))
  isGroupingObjects.value = isGroupingObjectsStorage
  SET_GROUPING_OBJECTS(isGroupingObjectsStorage)

  const clusterGeozonesStorage = JSON.parse(localStorage.getItem('clusterGeozones'))
  toggleGeozonesCluster.value = clusterGeozonesStorage === null ? true : clusterGeozonesStorage
})
</script>
